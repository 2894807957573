import React, { useState } from 'react';
import { useEffect, useMemo } from 'react';
import { getDropDownReferences } from 'src/ems/slices/referenceDropDown';
import { getAgents } from 'src/ems/slices/agent';
import { useDispatch, useSelector } from 'src/store';
import { useForm } from 'react-hook-form';
import MultiSelectDropdown from 'src/ems/components/rhf-components/MultiSelectDropdown';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import MultiSelectDropDownOptionsProps from 'src/ems/models/multiSelectDropDownOptionsProps';
import AuthService from 'src/ems/services/auth.service';
import { AgentPanelProps } from '../Models/ReportFilterPanelProps';
import { StatusEnum } from 'src/ems/enums/general.enums';

export const AgentPanel: React.FC<AgentPanelProps> = ({
  agent,
  network,
  subGroup,
  headerGroup,
  state,
  status
}) => {
  const methods = useForm();
  const dispatch = useDispatch();
  const { references } = useSelector((state) => state.referenceDropDown);
  const { agents } = useSelector((state) => state.agent);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    dispatch(getDropDownReferences(signal));
    dispatch(getAgents(signal));

    return () => {
      controller.abort();
    };
  }, [dispatch]);

  const [agentOptions, setAgentOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);
  const [headerGroupOptions, setHeaderGroupOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);
  const [networkOptions, setNetworkOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);
  const [subGroupOptions, setSubGroupOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);
  const [stateOptions, setStateOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);
  const [statusOptions, setStatusOptions] = useState<MultiSelectDropDownOptionsProps[]>([]);

  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [selectedHeaderGroup, setSelectedHeaderGroup] = useState([]);
  const [selectedSubGroup, setSelectedSubGroup] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const accessToken = window.localStorage.getItem('accessToken');
  const userInfoFromToken = AuthService.getUserFromJWTToken(accessToken);
  const headerGroupIds = userInfoFromToken.reportOnlyHeaderGroups;

  const groupIds = userInfoFromToken.reportOnlyGroups === undefined
    ? references.filter((a) => a.parentReferenceId !== null && headerGroupIds.includes(a.parentReferenceId.toString())).map((a) => a.value)
    : userInfoFromToken.reportOnlyGroups;

  const subGroupIds = userInfoFromToken.reportOnlySubGroups === undefined
    ? references.filter((a) => a.parentReferenceId !== null && groupIds.includes(a.parentReferenceId.toString())).map((a) => a.value)
    : userInfoFromToken.reportOnlySubGroups;

  const stateIds = userInfoFromToken.reportOnlyStateOrProvince === undefined ? [] : userInfoFromToken.reportOnlyStateOrProvince;

  const filterAndSortReferences = (type, ids) => {
    return references
      .filter((reference) => ids.includes(reference.value.toString()) && reference.type === type)
      .map((ref) => ({ label: ref.label, value: ref.value }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  useMemo(() => {
    setSelectedNetworks(network.defaultValues);
    setNetworkOptions(filterAndSortReferences('GRP', groupIds));
  }, [references]);

  useMemo(() => {
    setSelectedSubGroup(subGroup.defaultValues);
    setSubGroupOptions(filterAndSortReferences('SUB_GRP', subGroupIds));
  }, [references]);

  useMemo(() => {
    setAgentOptions(agents.map((agent) => ({
      label: agent.network ? `${agent.network} - ${agent.name}` : agent.name,
      value: parseInt(agent.entityId, 10)
    })).sort((a, b) => a.label.localeCompare(b.label)));
  }, [agents]);

  useMemo(() => {
    setSelectedHeaderGroup(headerGroup.defaultValues ? headerGroup.defaultValues : [0]);
    setHeaderGroupOptions(filterAndSortReferences('HEADER_GRP', headerGroupIds));
  }, [references]);

  useMemo(() => {
    setSelectedStates(state.defaultValues ? state.defaultValues : [0]);
    setStateOptions(filterAndSortReferences('STA', stateIds));
  }, [references]);

  useMemo(() => {
    setSelectedStatus(status.defaultValues ? status.defaultValues : [StatusEnum.ACTIVE]);
    setStatusOptions(filterAndSortReferences('STS', references.filter(ref => ref.type === 'STS').map(ref => ref.value.toString())));
  }, [references]);

  const handleNetworkChange = (data) => {
    const networkIds = data.map((item) => item.value);
    setSelectedNetworks(networkIds);

    const filteredSubGroup = networkIds && networkIds.length === 1 && networkIds[0] === 0
      ? filterAndSortReferences('SUB_GRP', subGroupIds)
      : references.filter((reference) => networkIds.includes(reference.parentReferenceId) && reference.type === 'SUB_GRP')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label));

    setSubGroupOptions(filteredSubGroup);

    const subGroupList = filteredSubGroup.map((item) => item.value);
    const newAgentOptions = agents.filter(a => ((networkIds.length === 1 && networkIds[0] === 0) || networkIds.includes(a.networkId)) || subGroupList.includes(a.subGroupId))
      .map((agent) => ({
        label: agent.network ? `${agent.network} - ${agent.name}` : agent.name,
        value: parseInt(agent.entityId, 10)
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    setAgentOptions(newAgentOptions);
  };

  const handleHeaderGroupChange = (data) => {
    const headerGroupIds = data.map((item) => item.value);
    setSelectedHeaderGroup(headerGroupIds);

    const filteredNetwork = headerGroupIds && headerGroupIds.length === 1 && headerGroupIds[0] === 0
      ? filterAndSortReferences('GRP', groupIds)
      : references.filter((reference) => groupIds.includes(reference.value.toString()) && headerGroupIds.includes(reference.parentReferenceId) && reference.type === 'GRP')
        .map((ref) => ({ label: ref.label, value: ref.value }))
        .sort((a, b) => a.label.localeCompare(b.label));

    setNetworkOptions(filteredNetwork);
    handleNetworkChange(filteredNetwork);
  };

  const handleStateChange = (data) => {
    const stateIds = data.map((item) => item.value);
    setSelectedStates(stateIds);


    const filteredAgents = stateIds.length === 1 && stateIds[0] === 0
      ? agents.filter((agent) => (selectedNetworks.length === 0 || selectedNetworks[0] === 0 || selectedNetworks.includes(agent.networkId)) && (selectedStatus.length === 0 || selectedStatus[0] === 0 || selectedStatus.includes(agent.statusId)))
      : agents.filter((agent) => stateIds.includes(agent.stateId) && (selectedNetworks.length === 0 || selectedNetworks[0] === 0 || selectedNetworks.includes(agent.networkId)) && (selectedStatus.length === 0 || selectedStatus[0] === 0 || selectedStatus.includes(agent.statusId)));

    setAgentOptions(filteredAgents.map((agent) => ({
      label: agent.network ? `${agent.network} - ${agent.name}` : agent.name,
      value: parseInt(agent.entityId, 10)
    })).sort((a, b) => a.label.localeCompare(b.label)));
  };

  const handleStatusChange = (data) => {
    const statusIds = data.map((item) => item.value);
    setSelectedStatus(statusIds);

    console.log('selectedStates', selectedStates);
    
    const filteredAgents = statusIds && statusIds.length === 1 && statusIds[0] === 0
      ? agents.filter((agent) => (selectedStates.length === 0 || selectedStates[0] === 0 || selectedStates.includes(agent.stateId)) && (selectedNetworks.length === 0 || selectedNetworks[0] === 0 || selectedNetworks.includes(agent.networkId)))
      : agents.filter((agent) => statusIds.includes(agent.statusId) && (selectedStates.length === 0 || selectedStates[0] === 0 || selectedStates.includes(agent.stateId)) && (selectedNetworks.length === 0 || selectedNetworks[0] === 0 || selectedNetworks.includes(agent.networkId)));

    setAgentOptions(filteredAgents.map((agent) => ({
      label: agent.network ? `${agent.network} - ${agent.name}` : agent.name,
      value: parseInt(agent.entityId, 10)
    })).sort((a, b) => a.label.localeCompare(b.label)));
  };

  const statusDefaultValues = status.defaultValues ? status.defaultValues : [StatusEnum.ACTIVE];

  return (
    <>
      {/* Agent Panel */}
      {(agent.showField || network.showField || state.showField || status.showField) && (
        <Card variant="outlined" sx={{ margin: '10px' }}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} margin={1} marginBottom={3}>
                <Typography variant="h4">Agent Panel</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {headerGroup.showField && headerGroupOptions !== null ? (
                <Grid item xs={12} md={3} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="headerGroup"
                    label="Header Group"
                    options={headerGroupOptions}
                    addAllOption={headerGroup.isAddAllOptions}
                    defaultValues={headerGroup.defaultValues}
                    handleChange={handleHeaderGroupChange}
                  />
                </Grid>
              ) : null}
              {networkOptions && network.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="network"
                    label="Network"
                    options={networkOptions}
                    addAllOption={network.isAddAllOptions}
                    defaultValues={network.defaultValues}
                    handleChange={handleNetworkChange}
                  />
                </Grid>
              ) : null}
              {subGroup.showField && subGroupOptions !== null ? (
                <Grid item xs={12} md={3} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="subGroup"
                    label="Sub Group"
                    options={subGroupOptions}
                    addAllOption={subGroup.isAddAllOptions}
                    defaultValues={subGroup.defaultValues}
                  />
                </Grid>
              ) : null}
              {agentOptions && agent.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="agent"
                    label="Agent"
                    options={agentOptions}
                    addAllOption={agent.isAddAllOptions}
                    defaultValues={agent.defaultValues}
                  />
                </Grid>
              ) : null}
              {stateOptions && state.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="state"
                    label="State"
                    options={stateOptions}
                    addAllOption={state.isAddAllOptions}
                    defaultValues={state.defaultValues}
                    handleChange={handleStateChange}
                  />
                </Grid>
              ) : null}
              {statusOptions && status.showField ? (
                <Grid item xs={12} md={4} sm={12} lg={4}>
                  <MultiSelectDropdown
                    name="status"
                    label="Status"
                    options={statusOptions}
                    addAllOption={status.isAddAllOptions}
                    defaultValues={statusDefaultValues}
                    handleChange={handleStatusChange}
                  />
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};
